<template>
  <div class="video-container">
    <div :id="'video-container-' + id"></div>
    <div v-if="videoSrc == ''" class="error">
      <h1>Video Unavailable</h1>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-youtube";

export default defineComponent({
  name: "Product-Video",
  props: {
    videoType: String,
    videoSrc: String,
    id: String,
  },
  methods: {
    loadVideo() {
      let videoContainer = document.querySelector(
        "#video-container-" + this.id
      );
      this.observer.unobserve(videoContainer);
      if (this.videoType == "youtube") {
        videoContainer.innerHTML =
          '<video id="video' + this.id + '" class="video-js" controls></video>';
      } else if (this.videoType == "mp4") {
        videoContainer.innerHTML =
          '<video :id="video' +
          this.id +
          '" class="video-js" controls playsinline><source src="' +
          this.videoSrc +
          '" type="video/mp4"/></video>';
      } else if (this.videoType == "gd") {
        videoContainer.innerHTML =
          '<iframe src="https://drive.google.com/file/d/' +
          this.videoSrc +
          '/preview" allowfullscreen="true"></iframe>';
      } else if ((this.videoType == "vimeo")){
          videoContainer.innerHTML =
          '<iframe src="https://player.vimeo.com/video/' +
          this.videoSrc +
          '" allowfullscreen="allowfullscreen"></iframe>';
      }

      if (this.videoType == "youtube" || this.videoType == "mp4") {
        this.player = videojs(
          "video" + this.id,
          this.dataSetup,
          function () {}
        );
        this.player.aspectRatio("16:9");
      }
    },
  },
  data() {
    return {
      player: null,
      objserver: null,
    };
  },
  computed: {
    dataSetup() {
      let dataSetup;
      if (this.videoType == "youtube") {
        dataSetup = {
          techOrder: ["youtube"],
          sources: [
            {
              type: "video/youtube",
              src: "https://www.youtube-nocookie.com/watch?v=" + this.videoSrc,
            },
          ],
        };
      } else {
        dataSetup = {};
      }
      return dataSetup;
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        this.loadVideo();
      }
    });
    let videoContainer = document.querySelector("#video-container-" + this.id);
    this.observer.observe(videoContainer);
  },
  beforeUnmount() {
    if (this.videoType == "youtube" || this.videoType == "mp4") {
      this.player.dispose();
    }
  },
});
</script>

<style scoped>
.video-container div {
  height: 100%;
  width: 100%;
}
.error {
  display: flex;
  text-align: center;
  place-items: center;
  justify-content: center;
  background: #dddddd;
}
</style>
<style>
iframe {
    width: 100%;
  height: 100%;
  border: 0px;
}
</style>